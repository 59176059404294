body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/*滚动条宽度*/
::-webkit-scrollbar {
  width: 10px;
}

/* 轨道样式 */
::-webkit-scrollbar-track {}

/* Handle样式 */
::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: rgba(130, 155, 203, 0.3);
}

/*当前窗口未激活的情况下*/
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.1);
}

/*hover到滚动条上*/
::-webkit-scrollbar-thumb:vertical:hover {
  background: rgba(130, 155, 203, 0.15);
}

/*滚动条按下*/
::-webkit-scrollbar-thumb:vertical:active {
  background-color: rgba(0, 0, 0, 0.7);
}

.ant-spin-container.ant-spin-blur div:first-child {
  display: block !important;
}

#ReadingToolbarPanel {
  display: none !important;
}