#loginpagewrap {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/images/backgroungImg.png");
    background-size: cover;
    background-repeat: no-repeat;

    .login_logo {
        padding-left: 50px;
        padding-top: 40px;
        display: flex;
        align-items: center;

        span {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            padding-left: 33px;
        }
    }

    #loginWrap {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;
        min-height: 460px;

        .bg {
            flex: 1;
            padding-left: 5%;

            h1 {
                font-size: 65px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }

            p {
                font-size: 50px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 100;
                color: #ffffff;
                margin-top: -35px;
            }
        }

        .jia86 {
            color: #a5a5a5;
            position: relative;
            top: 1px;
        }

        .ant-input {
            height: 50px;
            padding: 6.5px 11px;
        }

        .ant-row {
            position: relative;
            margin-top: 30px;
        }

        .ant-form-explain {
            position: absolute;
            bottom: -45%;
            left: 1%;
        }

        .ant-input-clear-icon {
            margin-right: 20px;
            margin-top: 4px;
        }

        .ant-input-affix-wrapper .ant-input:not(:first-child) {
            padding-left: 40px;
        }

        .form {
            position: relative;
            width: 320px;
            margin: 0 auto;
            flex: 0.8;
            display: flex;
            align-items: center;

            .ant-card-head-wrapper {
                padding-top: 20px;
                box-sizing: border-box;
            }

            .tilte {
                display: flex;
                align-items: center;
                justify-content: space-around;
                font-size: 22px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #919498;
            }

            .tilteSpan {
                font-size: 22px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #242a32;
            }
        }

        .sendCode {
            width: 113px;
            height: 38px;
            background: #f2f3f4;
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a7aaad;
            display: flex;
            align-items: center;
            margin-top: 2%;
            margin-right: 3%;
            justify-content: center;
            position: absolute;
            top: -8px;
            right: 8px;
        }

        .sendCodeBlue {
            background: #1775fe;
            color: #fdfdfe;
        }

        .sendCodegray {
            width: 100%;
            height: 100%;
            display: inline-block;
            background: #f2f3f4;
            display: flex;
            align-items: center;

            justify-content: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a7aaad;
        }

        #loginBtn {
            width: 380px !important;
            height: 50px !important;
            background: #1775ff !important;
            border-radius: 4px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            margin-top: 44px;

            span {
                color: #ffffff !important;
                font-size: 16px !important;
                font-family: PingFangSC-Regular, PingFang SC !important;
                font-weight: 400 !important;
            }
        }

        .login-form-forgot {
            float: right;
            margin-top: -10px;
            margin-bottom: 10px;
        }

        .authCodeDiv {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;

            .ant-form-item {
                width: 100%;
                padding-right: 10px;
            }
        }

        .login_type {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            margin-left: 215px;
            margin-top: 23px;
        }
    }

    .bottom {
        width: 100%;
        padding-bottom: 20px;
        color: #ffffff;
        .bottomTop {
            text-align: center;
            width: 100%;
            .helps{
                width: 644px;
                height: 40px;
                background: linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.4) 100%);
                border-radius: 4px;
                border: 1px solid;
                border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5)) 1 1;
                margin: 0 auto;
                font-size: 14px;
                display: flex;
                align-items: center;
                padding-left: 24px;
                span{
                    margin-right: 8px;
                }
                img{
                    width: 22px;
                    height: 22px;
                    margin: 0 8px;
                }
            }
        }

        .bottomUnder {
            width: 100%;
            text-align: center;
            margin-top: 15px;
        }
    }

    .ant-input-affix-wrapper .ant-input-prefix,
    .ant-input-affix-wrapper .ant-input-suffix {
        position: absolute;
        top: 27%;
        right: 0%;
    }

    .ant-input-affix-wrapper {
        padding: 0 !important;
    }

    .ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 40px;
        margin-top: -1px;
    }
}
