.logo {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00284d;
}
.trigger {
  font-size: 22px;
}
.globalMenu a {
  color: #fff !important;
}
.navRight {
  float: right;
  display: flex;
  align-items: center;
}
.bottomMenu {
  color: white;
  font-size: 14px;
  // background: #242424;
  padding: 13px 34px 20px;
  position: fixed;
  width: 200px;
  bottom: 0;
  cursor: pointer;
  // &:hover {
  //     background-color: #4b4b4b !important;
  //     color: #ffffff;
  // }
}
.headNav {
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  padding: 15px 30px;
  .title {
    color: #222;
    margin-top: 10px;
    .name {
      font-size: 20px;
      font-weight: bold;
    }
    .desc {
      padding-left: 15px;
    }
  }
}
.crmContainer {
  margin: 10px;
  padding: 24px;
  background: #fff;
  /* border-radius: 5px; */
  overflow: auto;
  .boldTittle {
    font-weight: bold;
    margin-bottom: 10px;
  }
}

// 这里时表格得样式；
.TableCommon {
  background: #fff;
  word-break: break-all;
  font-size: 14px;
  width: 100%;
  border-radius: 2px;
  td.tilteName {
    width: 7%;
    font-weight: bold;
    max-width: 70px;

    text-align: right !important;
  }
  td.rightTd {
    width: 20%;
    padding-right: 30px;
  }
  tr {
    height: 42px;
  }
  tr td {
    height: 42px;
    text-align: left;
  }
}

.errPage {
  padding-top: 100px;
  text-align: center;

  .textCon {
    text-align: left;
    margin: 0 auto;

    .desc {
      padding-top: 30px;
      font-size: 100px;
      color: #595959;
      line-height: 120px;
    }

    .text {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.427450980392157);
      line-height: 70px;
    }
  }
}

.whc_require:before {
  display: inline-block;
  margin-right: 4px;
  content: "*";
  font-family: SimSun;
  line-height: 1;
  font-size: 12px;
  color: #f04134;
}

.whc_require {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
.whc_link {
  color: #1890ff;
  cursor: pointer;
  &.oth {
    color: rgba(0, 0, 0, 0.45);
  }
  &:hover {
    color: #40a9ff;
  }
}

.ant-advanced-search-form {
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
  //margin-bottom: 16px!important;
}
.ant-advanced-search-form.min-height .ant-form-item {
  display: flex;
  margin-bottom: 5px !important;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.ant-radio-search-button .ant-radio-button-wrapper {
  &:first-child {
    border: none;
  }
  &:not(:first-child)::before {
    display: none;
  }
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-radius: 4px;
  }
  &.ant-radio-button-wrapper-checked {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    outline: none;
  }
  border: none;
  margin-right: 30px;
  line-height: 32px;
}

.search_like_antD .ant-radio-search-button .ant-radio-button-wrapper {
  margin-right: 5px;
}

.min-width.ant-radio-search-button .ant-radio-button-wrapper {
  margin-right: 16px;
}
.andt-winhc-form-inline {
  .ant-form-item {
    margin: 0;
    vertical-align: middle;
  }
}
.System {
  div {
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 12px;
    h1 {
      font-weight: 800;
      margin-top: 12px;
    }
  }
}
.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .scrollContainer {
    flex: 1;
    overflow: auto;
    padding: 10px;
    .scrollMain {
      padding: 24px;
      background: #fff;
      border-radius: 5px;
      .content {
        border-bottom: 1px solid #ebebeb;
        padding: 10px 0;
        color: #333;
        line-height: 30px;
      }
      .title {
        color: #333;
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
        overflow: hidden;
      }
      .item {
        display: flex;
        line-height: 40px;
        .lable {
          color: #666;
          min-width: 70px;
        }
        .desc {
          flex: 1;
          padding-right: 10px;
          //display: flex;
          .editForm {
            //flex: 1;
            display: inline-block;
          }
          .inner {
            min-width: 150px;
            display: inline-block;
            line-height: 20px;
            padding: 10px 0;
          }
        }
      }
      .boldTittle {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
}
.imgbox {
  width: 200px;
  height: 150px;
  background-color: gray;
  div {
    width: 200px;
    height: 150px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.reminders {
  padding: 15px 26px 0;
  position: relative;
  cursor: pointer;

  .close {
    height: 100%;
    position: absolute;
    right: 10px;
    top: 0;
    display: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  &:hover {
    background: #e6f7ff;

    .close {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
  }

  .title {
    padding-bottom: 5px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.86);
  }

  .desc {
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 8px;
  }
}

.checkMore {
  line-height: 46px;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid #e8e8e8;
}

.Statistical {
  position: relative;
  margin-bottom: 15px;
  .leftTip {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bold;
    color: #333;
  }
}
.timepick {
  margin: 0 auto;
  color: #333;
  font-size: 16px;
  text-align: center;
  div {
    display: inline-block;
    vertical-align: middle;
    min-width: 25px;
    .I {
      cursor: pointer;
      &:hover {
        color: #1890ff;
      }
    }
  }
}

.ant-modal-body {
  max-height: calc(100vh - 260px) !important;
  overflow: auto !important;
}

.EditTable {
  border: 1px solid #e9e9e9;
  background: #fff;
  text-align: center;
  word-break: break-all;
  font-size: 14px;
  width: 100%;
  border-radius: 2px;
  thead th {
    background: #ececec;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
  }
  tbody td {
    color: rgba(0, 0, 0, 0.65);
  }
  td.tilteName {
    background: #f7f7f7;
    font-weight: 700;
  }
  tbody td:hover {
    background: #ecf6fd;
  }
  tbody td.tilteName:hover {
    background: #f7f7f7;
  }
  tr {
    border-bottom: 1px solid #e9e9e9;
  }
  tr td,
  tr th {
    width: 50%;
    line-height: 22px;
  }
  tr td {
    padding: 10px 8px;
  }
  tr th {
    padding: 10px 8px 10px 27px;
  }
  td,
  th {
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
  }
  tr td:last-child {
    border-right: 0;
  }
  tr th:last-child {
    border-right: 0;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  tr:last-child th {
    border-bottom: 0;
  }
  .edit {
    color: rgba(0, 0, 0, 0.57);
    cursor: pointer;
    float: right;
    font-size: 17px;
  }
}
.ant-table-thead th {
  font-weight: bold !important;
}
.ant-table-thead {
  border: 1px solid #e8e8e8;
}
.ant-empty-normal {
  margin: 0;
}
.ant-form-item-required-span:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.ant-form-ant-form-horizontal-flex {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .ant-form-item {
    width: 33.3%;
  }
}
