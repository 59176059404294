// 组件文件list 的调整样式
.fileList {
  padding: 0px 10px;
  border-radius: 3px;
  display: inline-block;
}

.fileList a {
  padding-left: 6px;
  padding-right: 15px;
}

.fileList .close {
  float: right;
  opacity: 0;
  cursor: pointer;
}

.fileList:hover .close {
  opacity: 100;
}

.fileList:hover {
  background: #ecf6fd;
}

.hoverfff:hover {
  background: #fff;
}

.Privilege_title {
  margin: 5px 0;
  padding-left: 7px;
  border-left: 3px solid #6b6b6b;
}

.Privilege_title .title {
  color: #333;
  font-weight: 600;
  font-size: 14px;
}

.Privilege_title #selectfiles {
  padding-left: 50px;
  cursor: pointer;
  color: #333;
  font-size: 14px;
}
.yhc-gread-a{
  border:1px solid #0EB5A4;
  color: #0EB5A4;
  border-radius: 1px;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
  .left{
    background: #0EB5A4;
    color: #fff;
    padding: 0 7px;
  }
  .right{
    padding: 0 7px;
    font-weight: bolder;
  }
}
.yhc-gread-a-desc{
  color: #0EB5A4;
  font-size: 14px;
  line-height: 22px;
}
.yhc-gread-b{
  border:1px solid #379EEB;
  color: #379EEB;
  border-radius: 1px;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
  .left{
    background: #379EEB;
    color: #fff;
    padding: 0 7px;
  }
  .right{
    padding: 0 7px;
    font-weight: bolder;
  }
}
.yhc-gread-b-desc{
  color: #379EEB;
  font-size: 14px;
  line-height: 22px;
}
.yhc-gread-c{
  border:1px solid #EFB306;
  color: #EFB306;
  border-radius: 1px;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
  .left{
    background: #EFB306;
    color: #fff;
    padding: 0 7px;
  }
  .right{
    padding: 0 7px;
    font-weight: bolder;
  }
}
.yhc-gread-c-desc{
  color: #EFB306;
  font-size: 14px;
  line-height: 22px;
}
.yhc-gread-d{
  border:1px solid #EC412A;
  color: #EC412A;
  border-radius: 1px;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
  .left{
    background:#EC412A;
    color: #fff;
    padding: 0 7px;
  }
  .right{
    padding: 0 7px;
    font-weight: bolder;
  }
}
.yhc-gread-d-desc{
  color: #EC412A;
  font-size: 14px;
  line-height: 22px;
}
.yhc_require:before {
  display: inline-block;
  margin-right: 4px;
  content: "*";
  font-family: SimSun;
  line-height: 1;
  font-size: 12px;
  color: #f04134;
}

.yhc_require {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
