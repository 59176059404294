.StaffNameListManage {
  .threeBox {
    display: flex;

    .list {
      flex: 1;
      min-height: 210px;
      background-color: rgba(24, 144, 255, 0.0588235294117647);
      margin-right: 15px;
      min-width: 320px;
      padding: 20px;

      p {
        font-weight: bold;
        font-size: 20px;
        line-height: 0px;
        padding-top: 15px;
      }

      .upBox {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
}

// 工作报告；
.searchTabs {
  .nameLeft {
    margin-right: 20px;
    height: calc(100vh - 300px);
    overflow-y: scroll;

    // border: 1px solid gray;
    .listName {
      padding: 8px 0px 8px 15px;
      width: 150px;
    }

    .listActive {
      color: #1890ff;
      border-right: 3px solid #1890ff;
      background-color: #e6f7ff;
    }
  }

  .nameLeft::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    /**/
  }

  .nameLeft::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 2px;
  }

  .nameLeft::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }

  .nameLeft::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  .nameLeft::-webkit-scrollbar-corner {
    background: transparent;
  }

  .right {
    width: calc(100% - 200px);
  }

  .name {
    font-size: 16px;
  }

  .date {
    color: gray;
  }

  .submitTime {
    color: gray;
  }

  .twoBox {
    display: flex;
    margin-top: 5px;

    div {
      padding: 4px 10px;
      background-color: #ececec;
      margin-right: 15px;
      color: #333;
      font-size: 12px;
    }
  }

  .summary {
    margin-top: 15px;

    .sctent {
      padding-top: 8px;
      color: gray;

      .eary {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

// 业绩指标

.perforIndicators {
  .bigTittle {
    font-size: 16px;
    font-weight: bold;
    margin-right: 20px;
    padding-top: 4px;
  }

  .chartBox {
    height: 260px;
    // background-color: gray;
    margin-bottom: 30px;
  }

  .yingshoutingji {
    margin-bottom: 40px;
    & > div {
      flex: 1;
      height: 50px;
      border-right: 1px solid #d8d5d5;
      text-align: center;

      .moneyBold {
        font-size: 18px;
        font-weight: bold;
        padding-top: 8px;
      }
    }
  }
}
#FinalReport {
  display: flex;
  justify-content: space-between;
  .nav {
    display: flex;
    padding: 41px 32px 27px 27px;
    height: 96px;
    background: linear-gradient(180deg, #f2f7ff 0%, #ffffff 100%);
    justify-content: space-between;
    p {
      font-size: 26px;
      font-family: SourceHanSerifSC-Heavy, SourceHanSerifSC;
      font-weight: 800;
      color: rgba(0, 0, 0, 0.9);
      letter-spacing: 4px;
      margin-top: -6px;
    }
    img {
      width: 89px;
      height: 34px;
      -webkit-user-drag: none;
    }
    .ant-btn.ant-btn-primary {
      width: 80px;
      height: 32px;
      background: #1775ff;
      border-radius: 2px;
    }
  }
  .centre {
    padding: 0 32px 24px;
    background: #ffffff;
    .ant-col-3 {
      background: #f5f9ff;
      padding: 12px 16px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      border-top: 1px solid #e6f0ff;
      border-right: 1px solid #e6f0ff;
    }
    .ant-col-9 {
      padding: 12px 16px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      border-right: 1px solid #e6f0ff;
      border-top: 1px solid #e6f0ff;
    }
    .ant-col-21 {
      padding: 12px 16px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      border-right: 1px solid #e6f0ff;
      border-top: 1px solid #e6f0ff;
    }
    .ant-row-flex {
      border-left: 1px solid #e6f0ff;
    }
    .xian {
      width: 100%;
      display: inline-block;
      height: 1px;
      background: #2c2c2c;
      margin-bottom: -7px;
    }
    .xian1 {
      width: 100%;
      display: inline-block;
      height: 2px;
      background: #2c2c2c;
      margin-bottom: 12px;
    }
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      .ant-btn {
        width: 56px;
        height: 28px;
        background: #ffffff;
        border-radius: 2px;
      }
      .ant-btn.ant-btn-primary {
        width: 56px;
        height: 28px;
        background: #1775ff;
        border-radius: 2px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .text {
      .ant-input {
        background: #f9f9f9;
        border-radius: 2px;
      }
      .texts {
        width: 100%;
        height: 190px;
        overflow-x: scroll;
      }
    }
  }
  .footer {
    margin-top: 32px;
    background: rgb(250, 252, 255);
    border-radius: 4px;
    padding: 24px 35px 38px 56px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    .left {
      h5 {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1775ff;
        line-height: 30px;
        margin-bottom: 24px;
      }
      h6 {
        width: 100%;
        img {
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
        line-height: 13px;
      }
    }
    .right {
      border-left: 2px solid #f3f3f3;
      padding-left: 56px;
      img {
        width: 108px;
        height: 108px;
      }
      h6 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 16px;
        margin: 12px 0 32px;
      }
      h5 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
        line-height: 16px;
      }
    }
    .centres {
      padding: 0 16px;
      img {
        width: 64px;
        height: 72px;
      }
      div {
        border-left: 2px solid #f3f3f3;
        padding-left: 56px;
        padding-top: 21px;
        span {
          width: 63px;
          height: 20px;
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          // background-image: url(/images/backgrounds.png);
          // background-repeat: no-repeat;
          // background-size: 100% 100%;
          padding: 5px 13px 5px 10px;
          position: relative;
          img {
            width: 70px;
            height: 24px;
          }
          span {
            position: absolute;
            left: 8px;
            width: 72px;
            top: -8px;
          }
        }
        h5 {
          margin-top: 14px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          strong {
            font-size: 18px;
            font-family: YouSheBiaoTiHei;
            color: #242a32;
            margin-right: 17px;
          }
        }
      }
    }
  }
  .catalogue {
    position: sticky;
    top: 0;
    flex: 0.9;
    padding: 16px 24px;
    background: #ffffff;
    border-radius: 4px;
    .biaot {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      line-height: 24px;
      border-bottom: 2px solid #f2f3f4;
      padding-bottom: 16px;
    }
    height: max-content;
    .cata {
      padding-top: 8px;
      div {
        cursor: pointer;
        margin-top: 8px;
        border-left: 4px solid #f3f3f3;
        width: 100%;
        padding: 9px 16px;
      }
      div:hover {
        border-left: 4px solid #1775ff;
        background: linear-gradient(270deg, #ffffff 0%, #f3f3f3 100%);
      }
      .bg {
        border-left: 4px solid #1775ff;
        background: linear-gradient(270deg, #ffffff 0%, #f3f3f3 100%);
      }
    }
  }
}
.ContractModal {
  .ant-radio-wrapper.ant-radio-wrapper-in-form-item {
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    padding: 6px 0;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    position: relative;
    span {
      flex: 1;
    }
  }
  .ant-radio {
    position: absolute;
    top: 55%;
    right: 10%;

    width: 30px;
    height: 30px;
    background-image: url("../../assets/images/RadioNo.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-radio-input {
      opacity: 0;
      width: 30px !important;
      height: 30px !important;
    }
    .ant-radio-inner {
      opacity: 0;
      width: 30px;
      height: 30px;
    }
  }
  .ant-radio-checked {
    background-image: url("../../assets/images/RadioYes.png");
  }
}
//跟进记录
#FollowUpRecords {
  .div {
    p {
      margin: 0;
    }
    background-color: #fafafa;
    border-radius: 4px;
    display: 12px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding: 12px;
    .r {
      background-color: #eaf0fa;
      padding: 10px 7px;
      width: 45px;
      height: 45px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #1775ff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4px;
    }
    .l {
      margin-left: 20px;
      p {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        padding-bottom: 8px;
        span {
          border: 1px solid #1775ff;
          border-radius: 2px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 10px;
          color: #1775ff;
          display: inline-block;
          padding: 4px 6px;
          margin-right: 6px;
        }
      }
      .c {
        padding-top: 8px;
        padding-bottom: 0;
        border-top: 2px solid #eeeeee;
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }
}
