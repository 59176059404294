.ant-spin-container.ant-spin-blur div:first-child {
    display: none;
}
.EnterpriseQuery {
    height: 100%;
    overflow-y: auto;
    .ant-tag {
        max-width: 237px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3373fc;
        padding: 4px 12px;
        background: rgba(23, 117, 255, 0.1);
        border-radius: 4px;
        border: 0;
    }

    .ant-picker.ant-picker-range.ant-picker-borderless {
        position: absolute;
        height: 20px;
        div {
            display: none;
        }
        span {
            display: none;
        }
    }
}

.ant-modal-body {
    .p {
        display: flex;
        button {
            margin-left: 16px;
            // background: #1775ff;
            border-radius: 3px;
            padding: 5px 6px;
            box-sizing: border-box;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            // color: rgba(255, 255, 255, 0.9);
            display: flex;
            border: 0;
            align-items: center;
            justify-content: center;
            padding-left: 0;
        }
    }
    .p1 {
        margin-top: 32px;
        margin-bottom: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        // width: 416px;
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 8px;
    }
    .ant-tag {
        margin-bottom: 6px;
        background: rgba(23, 117, 255, 0.1);
        border-radius: 3px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1775ff;
        border: 0;
        padding: 2px 8px;
        box-sizing: border-box;
    }
}
.DescriptionsP {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1775ff;
    cursor: pointer;
    display: flex;
    justify-content: right;
    align-items: center;
    img {
        width: 14px;
        height: 14px;
        margin-left: 4px;
    }
}
.ant-descriptions-item-label {
    width: 20%;
}
.ant-checkbox-wrapper {
    margin: 0 !important;
    margin-right: 24px !important;
    // padding-top: 18px !important;
}
