.top {
    background: #ffffff;
    border-radius: 4px;
    .h1 {
        border-bottom: 1px solid #e7e7e7;
        padding: 22px 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        p {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.9);
        }
        div {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            span {
                color: #1775ff;
            }
            button {
                margin-left: 32px;
                width: 88px;
                height: 34px;
                border-radius: 4px;
                border: 1px solid #1775ff;
                color: #1775ff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #1775ff;
            }
        }
    }
    .h2 {
        padding: 22px 24px 32px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .condition1 {
            width: 100px;
            padding-top: 8px;
            float: right;
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1775ff;
        }
    }
}

.List {
    background-color: #ffffff;
    margin: 16px 0;
    :global {
        .ant-spin-nested-loading {
            height: 100%;
        }
    }
    .EmptyDiv {
        padding-bottom: 25px;
        width: 100%;
        img {
            display: block;
            margin: 0 auto;
            width: 132px;
            height: 98px;
            margin-top: 25px;
        }
        p {
            width: 100%;
            margin: 4px;
            display: flex;
            justify-content: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
        }
        h4 {
            margin-top: 16px;
            width: 100%;
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
        }
        Button {
            display: block;
            margin: 12px auto;
            background: #1775ff;
            border-radius: 4px;
        }
    }
    :global {
        .ant-tabs.ant-tabs-top {
            .ant-tabs-nav {
                padding: 0 38px;
                .ant-tabs-nav-wrap {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.9);
                    .ant-tabs-tab {
                        margin-right: 18px;
                        padding: 16px 0;
                    }
                }
                .ant-btn.ant-btn-default {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #1775ff;
                    width: 95px;
                    height: 34px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #1775ff;
                }
            }
        }
    }

    .ListDiv {
        height: 460px;
        overflow-x: auto;
    }
    .ListDiv1 {
        padding: 21px 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }
    .ListData {
        padding: 20px 0;
        box-sizing: border-box;
        display: flex;
        border-bottom: 1px solid #e7e7e7;
        margin: 0 24px;
        .ListTenter {
            flex: 1;
            h2 {
                display: inline;
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.9);
                width: 100%;
                cursor: pointer;
            }
            h4 {
                margin: 8px 0;
                span {
                    margin-right: 8px;
                    background: #eeeeee;
                    border-radius: 2px;
                    padding: 4px 8px;
                    box-sizing: border-box;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.65);
                }
                .span {
                    border-radius: 2px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }
            }
            h6 {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.6);
                span {
                    margin-left: 24px;
                }
                span:first-child {
                    margin-left: 0;
                }
            }
            h3 {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.6);
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }
    .ListData:first-child {
        padding-top: 0;
    }
}

.Center {
    width: 100%;
    background: #ffffff;
    margin: 0 12px;
    border-radius: 4px;
    margin-top: 16px;
    padding-bottom: 100px;
    .CenterDiv {
        padding: 23px 0;
        margin: 0 24px;
        border-top: 1px solid #e9e9e9;
    }
    h6 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #242a32;
        border-left: 4px solid #1775ff;
        margin-left: -23px;
        padding-left: 20px;
    }
    .CenterDiv1 {
        display: flex;
        margin-top: 24px;
        div {
            width: 100px;
            display: flex;
            // align-items: center;
        }
        .checks {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            input::-webkit-input-placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.4);
            }
        }
        .span {
            margin-right: 24px;
            cursor: pointer;
        }
    }
}
.Footer {
    // border-top: 1px solid #e7e7e7;
    z-index: 4;
    padding: 16px 24px;
    box-sizing: border-box;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 88%;
    Button {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
    }
    Button:first-child {
        color: #1775ff;
    }
}
