.custom-tooltip {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.95);
    color: #646464;

    min-width: 200px;
    height: auto;
    border-radius: 3px;
    font-size: 13px;
    box-shadow: 0px 0px 10px #aeaeae;
}

.custom-tooltip-title {
    margin: 0 12px;
    padding: 5px 0;
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: #e9e9e9;
    font-size: 14px;
    color: #222;
}
.custom-tooltip-content{
    margin: 0 12px;
    padding: 5px 0;
    font-size: 14px;
    line-height: 20px;
    color: #333;
}