.workTable {
  .tip {
    font-size: 20px;
    // font-weight: bold;
  }

  .right {
    color: gray;
    margin-bottom: -25px;

    .line {
      width: 1px;
      border-right: 1px solid #dcd3d3;
      height: 40px;
      margin: 10px 20px 0px 20px;
    }

    p {
      font-size: 16px;
      margin-top: 8px;
    }
  }
  .middleTitle {
    padding-bottom: 10px;
    .newTime {
      margin-left: 15px;
      font-size: 12px;
    }
  }

  .jc {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }
  .js {
    cursor: pointer;
    &:hover {
      color: #02a7f0;
    }
  }

  .reload {
    font-weight: bold;
    font-size: 16px;
    color: #333;
    margin-left: 8px;
  }
  .reload:active {
  }

  // 首页简报的样式;

  .custom-top-det {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 12px;
    cursor: pointer;
  }

  .custom-top-name {
    float: left;
  }

  .custom-top-name-det {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 16px;
    height: 22px;
  }

  .custom-top-name-img {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }

  .custom-top-name-mum {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 30px;
    line-height: 38px;
    height: 38px;
  }

  .custom-bottom-det {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 9px;
    margin-top: 8px;
    cursor: pointer;
  }

  .custom-bottom-det-ms {
    height: 30px;
    position: relative;
    margin: 0;
  }

  .custom-bottom-det-ms > span {
    font-size: 14px;
    line-height: 22px;
  }

  .tipText {
    padding: 30px 0px 30px 0px;
    text-align: center;

    .bold {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
  }

  .earnings {
    padding: 10px 5px 10px 30px;
    line-height: 35px;

    .bold {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    .smallBlock {
      padding-top: 5px;
      color: #333333;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .chartW {
    width: calc(100vw - 400px);
  }

  // 这边是排行榜的样式
  .ranking {
    .bold {
      font-weight: bold;
      color: #333333;
      margin-bottom: 25px;
    }

    .rangeNum {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }

    .rangeNumBg {
      background-color: #0f0f33;
      width: 20px;
      height: 20px;
      line-height: 20px;
    }

    .rankName {
      min-width: 70px;
      margin: 0 16px;
    }

    .icon_icon {
      width: 50px;
    }
  }
}

.notice {
  .ql-editor {
    img {
      max-width: 100%;
    }
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: center;
    .ant-btn.ant-btn-default {
      display: none;
    }
  }
}
.noticeDiv {
  width: 98%;
  .slick-track {
    height: 0 !important;
  }
  .slick-slide {
    pointer-events: auto !important;
  }
  .CarouselDiv {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    color: #333333;
    span {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .CarouselDiv:hover {
    span {
      color: #02a7f0;
    }
  }
}
.rank6List {
  .ant-list-item {
    padding: 4px 0;
    cursor: pointer;
    .ant-list-item-meta-title {
      font-family: "Arial Normal", "Arial", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: #333333;
      margin: 0;
      padding: 0;
    }
    .ant-list-item-meta-description {
      font-family: "Arial Normal", "Arial", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      color: #aaaaaa;
    }
    &:hover {
      .ant-list-item-meta-title {
        color: #02a7f0;
      }
    }
  }
}
